import { BehaviorSubject  } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RegisterUser, FullUser } from './shared/model';
import { ToastService } from './toast.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // http options used for making API calls
  private httpOptions: any;
  public user: any | undefined = undefined;
  // the token expiration date
  private isDecoPrompted = false;

  // the username of the logged in user
  public email: string = null;
  private exp: number;
  private id: number;
  private expirationDate: Date;
  private remeberme:boolean
  private isloggedout = true




  // error messages received from the login attempt
  public errors = [];
  access_token: any;
  public isBrwlocalStorageEnable = false;
  public isBrwSessionStorageEnable = false;
  public authenticationKo= new BehaviorSubject<boolean>(true);

  constructor(
    private http: HttpClient,
    private toastr: ToastService,
    private router: Router) {

    try{
      let s = "testStorageCapacity"
      localStorage.setItem('testStorageCapacity', s);
      let t = localStorage.getItem('testStorageCapacity')
      localStorage.removeItem('testStorageCapacity');
      this.isBrwlocalStorageEnable = s==t;


      sessionStorage.setItem('testStorageCapacity', s);
      let t2 = sessionStorage.getItem('testStorageCapacity')
      sessionStorage.removeItem('testStorageCapacity');
      this.isBrwSessionStorageEnable = s==t2;


    }catch (error){

      if (!environment.production) {
        console.log(error);
      }

    }



    if (this.getCookie('csrftoken') != null) {
      this.httpOptions = {

        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-CSRFToken': this.getCookie('csrftoken')
        })
      };
    } else {
      this.httpOptions = {

        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }

  }




  getCookie(name) {
    var cookieValue = null;

    if (document.cookie && document.cookie !== '' && document.cookie != null) {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }


    return cookieValue;

  }


  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint



  public login(user, rememberme,returnUrl) {

    this.remeberme = rememberme;
    let local_httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post('/api-token-auth/', JSON.stringify(user), local_httpOptions).subscribe(
      data => {
        this.isDecoPrompted = false;
        this.updateData(data['token']);
        this.toastr.success("Tu es connecté");
        //this.router.navigateByUrl('/dashboard');
        this.router.navigateByUrl(returnUrl);

      },
      err => {
        this.errors= [];
          this.errors.push("l'identifiant et le mot de passe ne sont pas reconnus");
/*         if (err['error'] != undefined){
          this.errors= err['error']['non_field_errors'];
        }else {
          this.errors.push(err);
        } */


          this.authenticationKo.next(true);
          this.toastr.error("Accès non autorisé");
          this.clearData(); // add to fix Apple issue #257

      }
    );
  }

  public register(registerUser: RegisterUser) {

    //return this.http.post<RegisterUser>(this.apiURL + '/register/', JSON.stringify(registerUser), this.httpOptions)
    this.http.post('/api-register/', JSON.stringify(registerUser), this.httpOptions).subscribe(
      data => {
        if (data["status"] == "success") {
          this.toastr.success("Tu as bien été enregistré - tu peux maintenant te connecté.")
          this.router.navigateByUrl('/login');
        } else if (data["status"] == "error") {
          this.toastr.error(data["message"])
        }

      },
      err => {

        this.toastr.error(err)
      }
    );

  }

  public getStorageBasedOnrememberMe() {

    if (this.remeberme && this.isBrwlocalStorageEnable) {
      return localStorage;

    } else {
      if (this.isBrwSessionStorageEnable) {
        return sessionStorage;
      }else if (this.isBrwlocalStorageEnable) {
        return localStorage;
      }
      else{
        this.toastr.error("Ce navigateur ne permet pas de conserver l'authentification utilisateur !")
      }
    }
  }


  public getid() {
    if (this.id == 0 || this.id == undefined) {
      this.getUserInfoFromToken();
    }

    return this.id;

  }

  public getToken() {

    if (this.access_token == undefined){
        this.getUserInfoFromToken();
    }


    return this.access_token;
  }

  public isLoggedIn(): boolean {
    let authToken = this.getStorages();

    let isAuthTokenPresent:boolean = authToken !== null;
    if(isAuthTokenPresent){
      this.refreshToken();
      this.authenticationKo.next(false);
      if(this.email == null){
        this.getUserInfoFromToken(authToken);
      }
    }else{
      this.authenticationKo.next(true);
    }

    return isAuthTokenPresent;
  }

  // Refreshes the JWT token, to extend the time the user is logged in
  public refreshToken() {
    if (this.isRefreshNeeded()){
      this.http.post('/api-token-refresh/', JSON.stringify({ token: this.getToken() }), this.httpOptions).subscribe(
        data => {
          this.updateData(data['token']);
          this.updateRefreshData();
        },
        err => {

          this.errors = err['error'];
          this.logout();
        }
      );
    }
  }

  public isRefreshNeeded(){
    let now = new Date();
    if (now>this.expirationDate) return false;

    let now_15min = new Date();

    now_15min.setMinutes(now_15min.getMinutes()+15);
    return now_15min>this.expirationDate;
  }

  public removeData() {
    this.email = null;
    this.id = 0;
    localStorage.removeItem('access_token');
    sessionStorage.removeItem('access_token');
    this.isDecoPrompted = true;
   // this.getStorageBasedOnrememberMe().removeItem('access_token');

  }

  private myhttpOptions() {


    return {
      headers: new HttpHeaders({
        'Authorization': 'JWT ' + this.getToken()
      })
    }
  }

  public logout() {


      if (!this.isDecoPrompted){
        this.http.get('/api/logout/', this.myhttpOptions()).subscribe();
        this.removeData();
        this.authenticationKo.next(true);
        //this.toastr.warn("Tu es désormais déconnecté. A bientôt :)")
        this.router.navigateByUrl('/login');
      }
  }
  private getStorages(){
    let authToken = sessionStorage.getItem('access_token');
    if (authToken === null){
      authToken = localStorage.getItem('access_token');
    }
    return authToken;
  }

  public getUserInfoFromToken(token = null) {

    if (token == null){
      token = this.getStorages();
    }

    if (token) {
      try {
        this.access_token = token;
        const token_parts = token.split(/\./);
        const token_decoded = JSON.parse(window.atob(token_parts[1]));
        this.email = token_decoded.email;
        this.id = token_decoded.user_id;
        this.exp =token_decoded.exp
        this.expirationDate = new Date(this.exp * 1000); //unixTimestamp
      } catch (error) {
        this.clearData();
      }
    }
  }


  private updateData(token) {
    this.getUserInfoFromToken(token);
    this.getStorageBasedOnrememberMe().getItem('access_token');
    this.getStorageBasedOnrememberMe().removeItem('access_token');
    this.getStorageBasedOnrememberMe().setItem('access_token', token);
    this.errors = [];
    //this.getUserInfoFromToken();
  }

  private updateRefreshData() {
    let cpt = this.getStorageBasedOnrememberMe().getItem('refresh_token_cpt');
    this.getStorageBasedOnrememberMe().removeItem('refresh_token_cpt');
    if (cpt == null) {
      cpt = "1";
    }else{
      let n_cpt = Number(cpt);
      n_cpt = n_cpt +1;
      cpt = String(n_cpt);
    }

    this.getStorageBasedOnrememberMe().setItem('refresh_token_cpt', cpt);
    this.errors = [];
    //this.getUserInfoFromToken();
  }

  private async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
}
  private clearData(){
    this.delay(500);// add to remove Apple issue #257 https://fschopp.github.io/safari-local-storage-bug/?prev-id=1656051308922
    this.removeData();
    this.authenticationKo.next(true);
    if (!this.isDecoPrompted){
      this.toastr.warn("Un problème est apparu, esssaye de te reconnecter s'il te plait")
      this.router.navigateByUrl('/login');
    }
  }
}
