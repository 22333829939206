import { UserInfoService } from './../../shared/utils/user-info.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { AdminSettingsPagination, ConnectedPeople, ConnectedPeopleLimited, MyNotification } from 'src/app/shared/model';
import { MyNotificationsService } from 'src/app/shared/my-notifications.service';
import { Subscription } from 'rxjs';
import { version } from '../../../../package.json';
import { CommonService } from 'src/app/shared/common.service';
import { environment } from 'src/environments/environment';
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Tableau de bord',  icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/Agenda', title: 'Agenda',  icon:'ni-bullet-list-67 text-red', class: '' },
    { path: '/maps', title: 'Parcours',  icon:'ni-pin-3 text-orange', class: ''},
    { path: '/faits', title: 'Faits de prédication',  icon:'ni-camera-compact text-red', class: '' },
    { path: '/user-profile', title: 'Mon profil',  icon:'ni-single-02 text-yellow', class: '' },
    { path: '/statistics', title: 'Activité',  icon:'ni ni-chart-bar-32 text-blue', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit,OnDestroy{
  public user: any;
  public menuItems: any[];
  public version: string = version;
  public isCollapsed = true;
  subscription: Subscription;
  public notifications: MyNotification[] = [];
  meSubscription: Subscription;
  settings: AdminSettingsPagination;
  settingsSubscription: Subscription;
  connectedPeople:ConnectedPeople[] | ConnectedPeopleLimited[];
  private showlist = false;
  modop_link = "";
  constructor(
    public common: CommonService,
    private router: Router,
    private _userService: AuthenticationService,
    public restApi: RestApiService,
    private _MyNotif: MyNotificationsService,
    private _userInfo: UserInfoService) {
    this.subscription = this._MyNotif.getMyNotifications().subscribe(notif => {
      if (notif) {
        this.notifications =notif;
      } else {
        // clear messages when empty message received
        this.notifications = [];
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.meSubscription.unsubscribe();
    this.settingsSubscription.unsubscribe();
  }
  seeList(){
    this.showlist = ! this.showlist
  }
  logout(){
    this._userService.logout();
    this.router.navigateByUrl('/login');
  }
  confirmNotif(notifId){
    this._MyNotif.confirmNotif(notifId);
  }

  getNotification(){
    let notifarray =[];
    notifarray = this.notifications;
    if(notifarray.length > 4){
      notifarray = notifarray.slice(0, 3);
    }
    return notifarray;
  }

  refreshConnected(){
    this.connectedPeople = null;
    this.restApi.getConnectedPeople().subscribe(
      (data: ConnectedPeople[] | ConnectedPeopleLimited[]) => {
        this.connectedPeople = data;
      }
    )
  }
  ngOnInit() {
    this.modop_link = environment.modoplink;
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });

    this.meSubscription = this._userInfo.me.subscribe(
      user=>{
        if (user != undefined){
          this.user = user;
        }
      }
    );

    //this.refreshConnected();

    this.settingsSubscription = this.common.settings.subscribe(
      data=>{
          this.settings = data;
      }
    );

    this.common.getFaq();

  }
  private faq(){
    if (this.settings != undefined && this.settings.results[0] != undefined) {
      let r = this.settings.results[0];
      return (r.id != undefined && r.id > 0);
    }
    return false
  }
}
