import { Component, OnInit, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RestApiService } from 'src/app/shared/rest-api.service';
import { AuthenticationService } from 'src/app/authentication.service';
import { MyNotificationsService } from 'src/app/shared/my-notifications.service';
import { MessageAlert, MyNotification } from 'src/app/shared/model';
import { Subscription } from 'rxjs';
import { UserInfoService } from 'src/app/shared/utils/user-info.service';
import { CommonService } from 'src/app/shared/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit,OnDestroy {
  public focus;
  public listTitles: any[];
  public location: Location;
  public user: any;
  public notifications: MyNotification[] = [];
  subscription: Subscription;
  meSubscription: Subscription;
  messageAlert: MessageAlert[];
  messageAlertSubscription: Subscription;

  @ViewChild('modalinfo') private modalinfo;


  constructor(
    location: Location,
    public common: CommonService,
    private router: Router,
    public restApi: RestApiService,
    private _userService: AuthenticationService,
    private _MyNotif: MyNotificationsService,
    private _userInfo: UserInfoService,
    private modalService: NgbModal,) {
    this.location = location;
    this.messageAlertSubscription = this._MyNotif.getMessageAlert().subscribe(alert => {
      if (alert) {
        this.messageAlert =alert;
      } else {
        // clear messages when empty message received
        this.messageAlert = null;
      }
    });


    this.subscription = this._MyNotif.getMyNotifications().subscribe(notif => {
      if (notif) {
        this.notifications =notif;
      } else {
        // clear messages when empty message received
        this.notifications = [];
      }
    });
  }


  confirmNotif(notifId){
    this._MyNotif.confirmNotif(notifId);
  }



  getNotification(){
    let notifarray =[];
    notifarray = this.notifications;
    if(notifarray.length > 4){
      notifarray = notifarray.slice(0, 3);
    }
    return notifarray;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.meSubscription.unsubscribe();
    //this.messageAlertSubscription.unsubscribe(); //#487
  }
  logout(){
    this._userService.logout();
    this.router.navigateByUrl('/login');
  }
  ngOnInit() {

    this.listTitles = ROUTES.filter(listTitle => listTitle);
    //this.getUserInfo();
    this.meSubscription = this._userInfo.me.subscribe(
      user=>{
        if (user != undefined){
          this.user = user;
        }
      }
    );
    /*//#487
    this.messageAlertSubscription = this._MyNotif.getMessageAlert().subscribe(
      data=>{
          this.messageAlert = data;
          let prevdata = this.common.modalInfoSub.getValue();
          if (data[0].message != prevdata.message){
            this.modalService.open(this.modalinfo);
            this.common.modalInfoSub.next(data[0]);
          }

      }
    );
    */

  /*   this.common.getMessageAlert(null); */


  }

  getTitle(){
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if(titlee.charAt(0) === '#'){
        titlee = titlee.slice( 1 );
    }

    for(var item = 0; item < this.listTitles.length; item++){
        if(this.listTitles[item].path === titlee){
            return this.listTitles[item].title;
        }
    }
    return titlee ;
  }

  private Alerte(){
    if (this.messageAlert != undefined && this.messageAlert[0] != undefined) {
      let r = this.messageAlert[0];
      return (r.id != undefined && r.id > 0);
    }
    return false
  }



}
