import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { version } from '../../../../package.json';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public isCollapsed = true;
  public version: string = version;
  constructor(private router: Router,public commonService: CommonService) { }

  ngOnInit() {
    var html = document.getElementsByTagName("html")[0];
    html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });

  }

  getLogo(name){
    return "assets/img/brand/"+name+"-"+environment.tpslogo+".png"
  }

  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
  }

  getTPSType(){
    return this.commonService.getTPSType();

  }
}
