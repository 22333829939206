import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AuthenticationService } from './authentication.service';
import { BrowserModule } from '@angular/platform-browser';
import { AvatarModule } from 'ngx-avatar';
import { ToastrModule } from 'ngx-toastr';
import { ToastService } from './toast.service';
import { CommonService } from './shared/common.service';
import { DatePipe,registerLocaleData } from '@angular/common';
import { LoaderService } from './shared/loader.service';
import localeFr from '@angular/common/locales/fr';
import { ClipboardModule } from 'ngx-clipboard';



registerLocaleData(localeFr);



@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    AvatarModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ClipboardModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
  ],
  providers: [AuthenticationService,ToastService, CommonService, DatePipe,
    {
      provide:HTTP_INTERCEPTORS, useClass:LoaderService, multi:true
    },
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
