import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  test: Date = new Date();

  constructor(public commonService: CommonService) {

   }

  ngOnInit() {
  }
  getTPSType(){
    return this.commonService.getTPSType();

  }
}
