import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private toastSuccessTitle = "OK.";
  private toastInfoTitle = "Info.";
  private toastErrorTitle = "Erreur.";
  private toastWarnTitle = "Attention !";
  constructor(private toastr: ToastrService) { }

  public success(msg){

    this.toastr.success(msg, this.toastSuccessTitle)
  }

  public error(msg){
    this.toastr.error(msg, this.toastErrorTitle)
  }

  public info(msg){
    this.toastr.info(msg, this.toastInfoTitle)
  }

  public warn(msg){
    this.toastr.warning(msg, this.toastWarnTitle)
  }
}
