import { UserInfoService } from "src/app/shared/utils/user-info.service";
import { Injectable, LOCALE_ID, OnInit } from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import {
  Observable,
  Subject,
  merge,
  OperatorFunction,
  BehaviorSubject,
} from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { RestApiService } from "./rest-api.service";
import { AuthenticationService } from "../authentication.service";
import {
  Attribution,
  Agendadata,
  TPSUser,
  Agenda,
  AgendaPagination,
  SpinnerAgenda,
  SpinnerCredAction,
  UserCredInstance,
  credUnitList,
  Creneau,
  ngDate,
  AgendCredInstanceListbyWeek,
  AgendCredInstanceListbyDay,
  CreneauWithCred,
  AgendCredInstanceList,
  AttributionCreation,
  statOfMonth,
  StatForMonth,
  Langue,
  CSVStats,
  AdminSettingsPagination,
  AdminSettings,
  MessageAlert,
} from "./model";
import { ToastService } from "../toast.service";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import * as i18n_fr from "src/app/shared/i18n_fr.json";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class CommonService implements OnInit{
  public req: number = 0;
  private isLoadingAgenda: SpinnerAgenda[] = [];
  private isLoadingCredAction: SpinnerCredAction[] = [];
  public isProcessDone = new Subject<boolean>();
  public settings = new Subject<AdminSettingsPagination>();
  public som = new Subject<statOfMonth>();
  public sbm = new Subject<StatForMonth | CSVStats>();
  public modalInfoSub = new BehaviorSubject(new MessageAlert());
  //private isSpecialActivity = false;

  public isSpecialActivityFunc(){

    let isSpecialActivity = environment.tpsstyle == "-special";
    return isSpecialActivity;
    //return this.isSpecialActivity;
  }
  public incrementRequestCounter() {
    this.req++;
    // console.log("increment counter req",this.req)
  }
  public i18nfr(key) {
    return i18n_fr["default"][key];
  }
  public decreaseRequestCounter() {
    if (this.req > 0) this.req--;
    // console.log("decrease counter req",this.req)
  }
  private checkSpinnerAgenda(agID: number) {
    for (let ag of this.isLoadingAgenda) {
      if (ag.agenda_id == agID) {
        return ag;
      }
    }
    return null;
  }
  public testRegexPwd(val) {
    if (val == "") {
      return true;
    }
    let regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    return regex.test(val);
  }
  y2k(number) {
    return number < 1000 ? number + 1900 : number;
  }
  getWeekNumber(date: ngDate | NgbDateStruct) {
    let todate = new Date(date.year, date.month - 1, date.day);
    var modDay = todate.getDay();
    if (modDay == 0) {
      todate.setDate(date.day - 1); //set monday at first day of week
    }
    let weekNumber = this.datepipe.transform(todate, "ww", "UTC +2");
    return Number(weekNumber);
  }
  /*
  getWeekNumber(date: ngDate) {
    let when = new Date(date.year, date.month, date.day);
    var newYear = new Date(date.year, 0, 1);
    var modDay = newYear.getDay();
    if (modDay == 0) modDay = 6; else modDay--;
    var daynum = ((Date.UTC(this.y2k(date.year), when.getMonth(), when.getDate(), 0, 0, 0) -
      Date.UTC(this.y2k(date.year), 0, 1, 0, 0, 0)) / 1000 / 60 / 60 / 24) + 1;
    if (modDay < 4) {
      var weeknum = Math.floor((daynum + modDay - 1) / 7) + 1;
    }
    else {
      var weeknum = Math.floor((daynum + modDay - 1) / 7);
      if (weeknum == 0) {
        date.year--;
        var prevNewYear = new Date(date.year, 0, 1);
        var prevmodDay = prevNewYear.getDay();
        if (prevmodDay == 0) prevmodDay = 6; else prevmodDay--;
        if (prevmodDay < 4) weeknum = 53; else weeknum = 52;
      }
    }
    return + weeknum - 4;
  }*/
  public checkSpinnerStatusByAgenda(agID: number) {
    let ag: SpinnerAgenda = this.checkSpinnerAgenda(agID);
    if (ag != null) {
      return ag.loading;
    }
    return false;
  }
  public setSpinnerAgenda(agID: number, status: boolean) {
    let ag: SpinnerAgenda = this.checkSpinnerAgenda(agID);
    if (ag != null) {
      ag.loading = status;
    } else {
      ag = new SpinnerAgenda();
      ag.agenda_id = agID;
      ag.loading = status;
      this.isLoadingAgenda.push(ag);
    }
  }
  private checkSpinnerCredAction(cred_id: number) {
    for (let cred of this.isLoadingCredAction) {
      if (cred.cred_id == cred_id) {
        return cred;
      }
    }
    return null;
  }
  public checkSpinnerStatusByCredAction(cred_id: number) {
    let cred: SpinnerCredAction = this.checkSpinnerCredAction(cred_id);
    if (cred != null) {
      return cred.loading;
    }
    return false;
  }
  public setSpinnerCredAction(cred_id: number, status: boolean) {
    let cred: SpinnerCredAction = this.checkSpinnerCredAction(cred_id);
    if (cred != null) {
      cred.loading = status;
    } else {
      cred = new SpinnerCredAction();
      cred.cred_id = cred_id;
      cred.loading = status;
      this.isLoadingCredAction.push(cred);
    }
  }
  public focus$ = new Subject<string>();
  public click$ = new Subject<string>();
  public agendas: AgendaPagination;
  public selectedAgenda: number;
  public TpsUsersList: any = [];
  public model: any;
  public isAttributed: boolean = false;
  public creneauWithCred:
    | AgendCredInstanceListbyWeek
    | AgendCredInstanceListbyDay
    | AgendCredInstanceList;
  public datemodel: NgbDateStruct;
  public date: { year: number; month: number };
  public agenda_id: number;
  public type: string;
  public selectedCredInstance: any;
  public faq: AdminSettingsPagination;
  constructor(
    public sanitizer: DomSanitizer,
    public restApi: RestApiService,
    private userDataService: UserInfoService,
    private _userService: AuthenticationService,
    private toastr: ToastService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    //this.isSpecialActivity = environment.tpsstyle == "-special";
  }

  private loadTPSUsersAuto(pattern) {
    return this.restApi.getTPSUsersAuto(pattern).subscribe((data: {}) => {
      this.TpsUsersList = data;
    });
  }
  getDayByDate(date) {
    let splitted = date.split("/");
    let newDate = new Date(splitted[2] + "-" + splitted[1] + "-" + splitted[0]);
    let days = [
      "dimanche",
      "lundi",
      "mardi",
      "mercredi",
      "jeudi",
      "vendredi",
      "samedi",
    ];
    let day = days[newDate.getDay()];
    return day;
    //return this.datepipe.transform(days[newDate.getDay()-1], 'dd/MM/yyyy');
  }
  getToday() {
    let str1 = this.datepipe.transform(
      (<AgendCredInstanceListbyDay>this.creneauWithCred).today,
      "dd/MM/yyyy"
    );
    let str2 = this.getDayByDate(str1);
    return str2 + " " + str1;
  }
  public init_view(agenda_id, type, loadneeded = true) {
    if (this.agenda_id == undefined) {
      this.agenda_id = agenda_id;
    }
    if (this.datemodel == undefined) {
      this.datemodel = null;
    }
    this.type = type;
    this.model = null;
    this.creneauWithCred = null;
    this.selectedCredInstance = null;
    this.isAttributed = false;
    if (loadneeded) {
      this.goAgendaByDate();
    }
  }
  public getAgendas() {
    if (this.agendas != null || this.agendas != undefined) {
      return this.agendas;
    } else {
      this.restApi.getAgendas(null).subscribe((data: AgendaPagination) => {
        this.agendas = new AgendaPagination(data);
        return this.agendas;
      });
    }
  }
  public getAllAgenda() {
    //this.restApi.getAgendas(null).subscribe((data: AgendaPagination) => {
    this.restApi.getAgendas("all").subscribe((data: AgendaPagination) => {
      this.agendas = new AgendaPagination(data);
      this.loadCreneau();
    });
  }
  //return 1st AgendaID which have the parcoursID
  private getAgendaId(parcoursId) {
    if (this.agendas) {
      let notfound = true;
      this.agendas.results.forEach((ag) => {
        if (ag.parcours == parcoursId) {
          this.selectedAgenda = ag.id;
          this.agenda_id= ag.id;
          notfound = false;
          return;
        }
      });


      if (notfound) {
        this.getAgendaByParcoursId(parcoursId);
      }else{
        this.loadCreneau();
      }


    }
  }
  public getPagAgenda(parcours_id) {
    //this.consolelog("parcours_id",parcours_id);
    //this.restApi.getAgendas(null).subscribe((data: AgendaPagination) => {
    this.restApi.getAgendas(null).subscribe((data: AgendaPagination) => {
      this.agendas = new AgendaPagination(data);


      if (!isNaN(parcours_id) && parcours_id != "") {
        this.getAgendaId(parcours_id);
      }else{
        this.getAgendaId(this.agendas.results[0].parcours)
      }
    });
  }
  public getAgendaByParcoursId(pid) {
    this.restApi.getAgendaByParcoursId(pid).subscribe((data: Agenda) => {
      this.agendas.results.push(data);
      this.selectedAgenda = data.id;
      this.agenda_id = data.id;
      this.loadCreneau();
    });
  }
  public getAgenda(page) {
    //this.restApi.getAgendas(null).subscribe((data: AgendaPagination) => {
    this.restApi.getAgendas(page).subscribe((data: AgendaPagination) => {
      this.agendas = new AgendaPagination(data);
      this.selectedAgenda = this.agendas.results[0].id;
      this.loadCreneau();
    });
  }
  public getOnlyAgenda(agendadata) {
    this.restApi.getAgendas(null).subscribe((data: AgendaPagination) => {
      this.agendas = new AgendaPagination(data);
      if (agendadata.agenda_id == 0) {
        let defaultAgenda: number = 1;
        for (let ag of this.agendas.results) {
          let agenda: Agenda = <Agenda>ag;
          if (agenda.parcours_name.toLowerCase().includes("centre")) {
            //to do better by adfdin default attribute
            defaultAgenda = agenda.id;
          }
        }
        agendadata.agenda_id = defaultAgenda;
      }
    });
  }
  getProgressType(cre, num) {
    let progress = this.getProgress(cre, num);
    let type = "danger";
    if (progress < 66 && progress > 33) {
      type = "danger";
    } else if (progress >= 66 && progress < 99) {
      type = "warning";
    } else if (progress > 99) {
      type = "success";
    }
    return type;
  }
  nextAgenda() {
    //console.log(this.type);
    this.creneauWithCred = null;
    let agendadata = new Agendadata(this.agenda_id, this._userService.getid());
    agendadata.position = 1;
    agendadata.viewType = this.type;
    if (this.datemodel) {
      agendadata.tdate = this.datemodel;
    }
    this.setSpinnerAgenda(agendadata.agenda_id, true);
    this.getCreneauWithCred(agendadata);
  }
  prevAgenda() {
    let agendadata = new Agendadata(this.agenda_id, this._userService.getid());
    agendadata.position = -1;
    agendadata.viewType = this.type;
    if (this.datemodel) {
      agendadata.tdate = this.datemodel;
    }
    this.setSpinnerAgenda(agendadata.agenda_id, true);
    this.getCreneauWithCred(agendadata);
  }
  getProgress(cre, num) {
    let cred = this.getDayCredByNumber(cre, num);
    if (cred != undefined) {
      return (
        (cred.attributions.length /
          (cred.attributions.length + cred.remaining_proc)) *
        100
      );
    } else {
      return 0;
    }
  }
  getDayCredByNumberParam(cred) {
    if (cred != undefined) {
      return btoa(cred.date + "/" + this.selectedAgenda);
    }
    return "";
  }
  public getDayCredByNumber(cre, num) {
    if (cre == undefined) {
      return null;
    }
    if (num < 1 || num > 7) return null;
    if (num == 1) return cre.lundi;
    if (num == 2) return cre.mardi;
    if (num == 3) return cre.mercredi;
    if (num == 4) return cre.jeudi;
    if (num == 5) return cre.vendredi;
    if (num == 6) return cre.samedi;
    if (num == 7) return cre.dimanche;
  }
  public amipresent() {
    let _return = true;
    if (
      this.selectedCredInstance != undefined ||
      this.selectedCredInstance != null
    ) {
      for (let attr of this.selectedCredInstance.attributions) {
        if (attr.user_id == this._userService.getid()) return false;
      }
    }
    return _return;
  }
  public loadCreneau() {
    let agenID = this.agenda_id;
    if (
      this.selectedAgenda != undefined &&
      this.selectedAgenda != this.agenda_id
    ) {
      agenID = this.selectedAgenda;
      //this.creneauWithCred.clist = null//not UX friendly
    }
    let agendadata = new Agendadata(agenID, this._userService.getid());
    this.agenda_id = agenID;
    this.selectedAgenda = agenID;
    agendadata.viewType = this.type;
    this.setSpinnerAgenda(agenID, true);
    this.getCreneauWithCred(agendadata);
  }
  enregistrer() {
    let userid = this.model.id;
    if (userid == undefined) {
      this.toastr.error(
        "Merci de sélectionner un proclamateur figurant dans la liste !"
      );
    } else {
      this.enregistrerGenerique(userid);
    }
  }
  menregistrer() {
    let userid = this._userService.getid();
    if (userid == undefined) {
      this.toastr.error("Une erreur est survenue - merci de recommencer");
    } else {
      this.enregistrerGenerique(userid);
    }
  }
  public isPassedCU(cre: credUnitList) {
    let date_str =
      cre.date.year +
      "-" +
      cre.date.month +
      "-" +
      cre.date.day +
      " " +
      cre.heure_debut;
    return this.isPassedGeneric(date_str);
  }
  public isPassedWeek(creweek: AgendCredInstanceListbyWeek, daynum) {
    //cre:CreneauWithCredbyWeek
    let ispassed = true;
    for (let cre of creweek.clist) {
      let tmp: UserCredInstance = this.getDayCredByNumber(cre, daynum);
      if (tmp != undefined) {
        let dt = tmp.date.split("/");
        let hr = tmp.creneau.split(" - ");
        let date_str = dt[2] + "-" + dt[1] + "-" + dt[0] + " " + hr[1];
        if (!this.isPassedGeneric(date_str)) {
          return false;
        }
      }
    }
    return ispassed;
  }
  private isPassedGeneric(dateStr: string) {
    //let cred_date = new Date(Date.parse(dateStr));
    let now = new Date(Date.now());
    let arr = dateStr.split(/[- :]/);
    let cred_date = new Date(
      Number(arr[0]),
      Number(arr[1]) - 1,
      Number(arr[2]),
      Number(arr[3]),
      Number(arr[4]),
      Number(arr[5])
    );
    return now > cred_date;
  }
  public isPassed(cre: UserCredInstance) {
    let date_fin = cre.creneau.split(" - ")[1];
    let date_format = cre.date.split("/");
    let date_str =
      date_format[2] +
      "-" +
      date_format[1] +
      "-" +
      date_format[0] +
      " " +
      date_fin;
    return this.isPassedGeneric(date_str);
  }
  public isStarted(cre: UserCredInstance) {
    let date_debut = cre.creneau.split(" - ")[0];
    let date_format = cre.date.split("/");
    let date_str =
      date_format[2] +
      "-" +
      date_format[1] +
      "-" +
      date_format[0] +
      " " +
      date_debut;
    return this.isPassedGeneric(date_str);
  }
  private enregistrerGenerique(userid) {
    let attribution = new Attribution();
    attribution.user = userid;
    if (this.selectedCredInstance.cre_instance_id) {
      attribution.creinstance = this.selectedCredInstance.cre_instance_id;
    } else if (this.selectedCredInstance.cred_id) {
      attribution.creinstance = this.selectedCredInstance.cred_id;
    }
    this.restApi
      .createAttributions(attribution)
      .subscribe((data: AttributionCreation) => {
        this.isProcessDone.next(true);
        this.goAgendaByDate();
        this.toastr.success("L'attribution a bien été enregistré ");
      });
  }
  arrayOne(n: number): any[] {
    return Array(n);
  }
  formatter = (result: TPSUser) => result.prenom + " " + result.nom;
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  backgoundCred(cred) {
    if (cred != null) {
      if (cred.is_urgent && !cred.is_brother_needed) {
        return "#ffcccc";
      } else if (cred.is_brother_needed && !cred.is_urgent) {
        return "#4bcffa";
      } else if (cred.is_brother_needed && cred.is_urgent) {
        return "#eccc68";
      }
    }
    return "";
  }
  goAgendaByDate() {
    //type = 'week' or 'month' or 'day'
    //agenda_id = 1 so far
    //this.creneauWithCred = [];
    let agendadata = new Agendadata(this.agenda_id, this._userService.getid());
    agendadata.viewType = this.type;
    agendadata.tdate = this.datemodel;
    if (agendadata.agenda_id != undefined && agendadata.agenda_id>0) {
      //can be from dahsboard
      this.setSpinnerAgenda(agendadata.agenda_id, true);
      this.getCreneauWithCred(agendadata);
    }
  }
  public getFirstSlotOfTheDay(
    creneauWithCred: AgendCredInstanceList,
    nbOfWeek,
    day
  ) {
    if (creneauWithCred != undefined && nbOfWeek >= 0) {
      for (let cre of creneauWithCred.clist) {
        if (this.getDayCredByNumber(cre.clist[nbOfWeek], day) != null) {
          return this.getDayCredByNumber(cre.clist[nbOfWeek], day);
        }
      }
    }
    return undefined;
  }
  private getCreneauWithCred(agendadata: Agendadata) {
    return this.restApi
      .getCreneauWithCred(agendadata)
      .subscribe((data: AgendCredInstanceListbyWeek) => {
        this.creneauWithCred = data;
        let start_at = data["start_at"];
        let stday = new Date(
          start_at["year"],
          start_at["month"] - 1,
          start_at["day"]
        );
        let stngd = new ngDate();
        stngd.year = stday.getFullYear();
        stngd.month = stday.getMonth() + 1;
        stngd.day = stday.getDate();
        if (this.type == "week") {
          let today = new Date();
          let ngd = new ngDate();
          ngd.year = today.getFullYear();
          ngd.month = today.getMonth() + 1;
          ngd.day = today.getDate();
          if (this.getWeekNumber(ngd) == this.getWeekNumber(stngd)) {
            this.datemodel = ngd;
          } else {
            this.datemodel = stngd;
          }
        } else {
          this.datemodel = stngd;
        }
        this.setSpinnerAgenda(agendadata.agenda_id, false);
      });
  }
  desinscrire(attr_id?: number) {
    if (attr_id) {
      this.deleteAttribution(attr_id);
    } else {
      this.deleteAttribution(this.model.attr_id);
    }
  }
  cancelUnsubscribe(attr_id?: number) {
    //ugly
    if (attr_id) {
      this.deleteAttribution(attr_id, true);
    } else {
      this.deleteAttribution(this.model.attr_id, true);
    }
  }
  private deleteAttribution(id, cancel: boolean = false) {
    return this.restApi.deleteAttribution(id, cancel).subscribe((data) => {
      this.isProcessDone.next(true);
      this.goAgendaByDate();
      if (cancel) {
        this.toastr.success("L'attribution est active ");
      } else {
        if (
          this.userDataService.user.is_admin ||
          this.userDataService.user.is_planificator
        ) {
          this.toastr.success("L'attribution a bien été supprimée ");
        } else {
          this.toastr.success("Une demande de désinscription a été envoyé ");
        }
      }
    });
  }
  search: OperatorFunction<string, string[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$;
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) => {
        if (term === "") {
          term = "*"; //to fetch all
        }
        if (term.length > 0) {
          this.loadTPSUsersAuto(term);
          return this.TpsUsersList;
        } else {
          return [];
        }
      })
    );
  };
  public disableToolTipPolicy() {
    return window.navigator.userAgent.indexOf("Android") !== -1;
  }
  getSmallDate(date) {
    if (date != undefined) {
      let s = date.split(" ");
      return s[0];
    }
  }
  getDayDate(date) {
    if (date != undefined) {
      let s = date.split("/");
      return s[0];
    }
  }
  public isSmartphone() {
    return (
      window.navigator.userAgent.indexOf("Android") !== -1 ||
      window.navigator.userAgent.indexOf("iPhone") !== -1
    );
  }
  public whichMobileOS(): string {
    if (window.navigator.userAgent.indexOf("iPhone") !== -1) {
      return "iOS";
    } else if (window.navigator.userAgent.indexOf("Android") !== -1) {
      return "Android";
    } else return "Other";
  }
  getMonthStats() {
    this.restApi.getStats().subscribe((data: statOfMonth) => {
      this.som.next(data);
    });
  }
  getStatsByMonth(month, year, csv) {
    //change with date picker
    this.restApi
      .getStatsByMonth(month, year, csv)
      .subscribe((data: StatForMonth | CSVStats) => {
        this.sbm.next(data);
      });
  }
  get_item_by_langue(langue: Langue, rapport) {
    let rsp = rapport.filter(
      (item) =>
        item.item != null &&
        item.item != undefined &&
        item.item.langue.id == langue.id
    );
    return rsp;
  }
  consolelog(title, log) {
    if (!environment.production) {
      console.log("----------" + title + "------------------");
      console.log(log);
      console.log();
    }
  }
  public getFaq() {
    this.restApi
      .getAdminSettings(null)
      .subscribe((data: AdminSettingsPagination) => {
        let faq = new AdminSettingsPagination(data);
        this.settings.next(faq);
      });
  }
  getSmallHour(hour) {
    if (hour != undefined) {
      let h = hour.split(":", 2);
      return h[0] + "h" + h[1];
    }
  }
  getTpsStyle() {
    return "bg-gradient-danger" + environment.tpsstyle;
  }
  getLogo(name) {
    return "assets/img/brand/" + name + "-" + environment.tpslogo + ".png";
  }
  public isCreBookable(ag: Agenda, cre: UserCredInstance) {
    let ag_enabled_date = ag.enabled_at;
    let enabled_date = new Date(
      ag_enabled_date.year,
      ag_enabled_date.month - 1,
      ag_enabled_date.day
    );
    let ag_disabled_date = ag.disabled_at;
    let disabled_date = new Date(
      ag_disabled_date.year,
      ag_disabled_date.month - 1,
      ag_disabled_date.day
    );
    let date_format = cre.date.split("/");
    let cre_date = new Date(
      Number(date_format[2]),
      Number(date_format[1]) - 1,
      Number(date_format[0])
    );
    if (cre_date < enabled_date) {
      return "Agenda non commencé";
    } else if (cre_date > disabled_date) {
      return "Agenda terminé";
    } else if (this.isPassed(cre)) {
      return "Créneau terminé";
    } else return "";
  }



  getTPSType(){
    if (!this.isSpecialActivityFunc()){
      return "TPS Caen";
    }

  }
}
