import { Injectable, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { ToastService } from '../toast.service';
import { CallForAction, MessageAlert, MyNotification, MyNotificationMetrix, Polling, PriorityEnum, SmsStatus } from './model';
import {interval} from "rxjs/internal/observable/interval";
import {startWith, switchMap} from "rxjs/operators";
import { RestApiService } from './rest-api.service';
import { Observable, Subject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class MyNotificationsService {

  public mynotifi = new Subject<MyNotification[]>();
  public messAlert = new Subject<MessageAlert[]>()


  constructor( private _userService: AuthenticationService, public restApi: RestApiService, private toastr: ToastService, private common: CommonService) {
    interval(10000)
    .pipe(
      startWith(0),
      switchMap(() =>  this.restApi.getPolling(this._userService.getid()))
    )
    .subscribe(res => {
      this.mynotifi.next(res.my_notification);
      this.messAlert.next(res.message_alert);
    });

  }


  getMyNotifications(): Observable<MyNotification[]> {
    return this.mynotifi.asObservable();
  }

  getMessageAlert(): Observable<MessageAlert[]> {
    return this.messAlert.asObservable();
  }

  sendNotificationByCred(ffrom, cred, message, type:PriorityEnum, email=false,sms=false){
    let notification = new MyNotification();
    notification.email = email;
    notification.sms = sms;
    notification.ffrom = ffrom;
    notification.notification= message;
    notification.cred = cred.cred_id;
    notification.priority = type
    this.createNotification(notification, cred);
  }

  sendNotificationByUsers(ffrom, users, message){
    let notification = new MyNotification();
    notification.ffrom = ffrom;
    notification.notification= message;
    notification.users = users;
    this.createNotification(notification);
  }

  private loadNotifications() {
    return this.restApi.getNotificationByUser(this._userService.getid()).subscribe((data: MyNotification[]) => {
      this.mynotifi.next(data)
    })
  }

  private createNotification(notification: MyNotification, cred=null) {
    if (notification.email || notification.sms){
      if(cred){this.common.setSpinnerCredAction(cred.cred_id, true)};
      return this.restApi.createNotificationEmailorSMS(notification).subscribe((data: CallForAction | SmsStatus)=>
    {
      if (notification.email){
        let tmp =  data as CallForAction;
        if(cred){cred.callforaction = tmp;}
        this.toastr.success("La demande de notification par email a été correctement traitée - "+tmp.calledusers.length+" proclamateur.s notifié.s")
      }else if(notification.sms){
        let tmp =  data as SmsStatus;
        this.toastr.success("La demande de notification par sms a été correctement traitée avec le status: "+tmp.status+" ")
      }
      if(cred){this.common.setSpinnerCredAction(cred.cred_id, false)}
    });
    }else{
      return this.restApi.createNotification(notification).subscribe((data: any)=>
      {
        this.toastr.success("La notification a été envoyé")
      });
    }
  }

  confirmNotif(notifId){
    let notfi = new MyNotificationMetrix()
    notfi.user = this._userService.getid();
    notfi.notification = notifId;
    return this.restApi.createNotificationMetrix(notfi).subscribe((data: {}) => {
      this.toastr.success("Merci pour ta confiance")
      this.loadNotifications();
    })
  }
}
