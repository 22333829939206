import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { UserCredInstanceList, TPSUser, Attribution, Creneau, CreneauWithCred, Agendadata, AgendCredInstanceList, Dispo, DispoPerWeek, Time, Assemblee, AgendCredInstanceListbyWeek, AgendCredInstanceListbyDay, Rapport, FullUser, MyNotification, MyNotificationMetrix, PlanificatorAgenda, credUnitList, PlanWeek, Agenda, Parcours, PlanificatorUser, PlanificatorAll, Faits, Item, Langue, Warehouse, FaitPagination, GItemPagination, ItemPagination, LanguePagination, WarehousePagination, FullUserPagination, PlanificatorAllPagination, AgendaPagination, CreneauPagination, ParcoursPagination, userMetrix, AttributionCreation, ngDate, CallForAction, Updatepwd, ItemTypesPagination, IType, SmsStatus, UserCredInstance, statOfMonth, StatForMonth, CandidateDispo, UserCredInstancePagination, CSVStats, AdminSettingsPagination, AdminSettings,SuggestionsPagination,MessageAlert, MessageAlertPagination, Polling, ConnectedPeople, ConnectedPeopleLimited, PwdChangeObj, RulesAttr, RuleDescription, RuleCreneau, RuleCreneauPagination, pwdChangeRequestObj, GItem } from './model';
import { AuthenticationService } from '../authentication.service';
import { ToastService } from '../toast.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class RestApiService {
  [x: string]: any;


  sanitizeUrl(url){
    if (url.includes("?") && url.endsWith("/")){
      url = url.slice(0,-1);//remove "/"
    }
    if (!url.includes("?") && !url.endsWith("/")){
      url = url+'/'
    }
    return url
  }

  httpOptions() {
    this._userService.refreshToken();

    return {
      headers: new HttpHeaders({
        'Authorization': 'JWT ' + this._userService.getToken()
      })
    }
  }

  httpOptionsPost() {
    this._userService.refreshToken();

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + this._userService.getToken()
      })
    }
  }

  httpOptionsPostAnonymous() {
    let csrftoken = this.getCookie('csrftoken');
    if (csrftoken) {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-CSRFToken':csrftoken
        })
      }
    }else {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      }
    }
  }


  // Define API
  apiURL = '/api';
  retry= 0;
  constructor(private http: HttpClient, private _userService: AuthenticationService,private toastr: ToastService) {

    //this.setup();

  }

  private setsetPaginationPathAll(){
    return this.setPaginationPath(500,null)
  }

  private setPaginationPath(limit, offset){
    if (offset)
      return "?limit="+limit+"&offset="+offset;
    return "?limit="+limit;
  }

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
  }
  //private csrftoken = this.getCookie('csrftoken');



  // Http Options
  //private setup(){
  //  if (this.csrftoken != null){
  //    this.httpOptionsPost() = {
  //      headers: new HttpHeaders({
  //        'Content-Type': 'application/json',
  //        'X-CSRFToken':this.csrftoken,
  //        'Authorization': 'JWT ' + this._userService.getToken()
  //      })
  //    }
  //  }
  //}

  private getPath(pageUrl){

    let path = '';
    if (pageUrl == "all"){
      path = this.setsetPaginationPathAll();
    }
    else if(Number.isInteger(pageUrl)){
      path = this.setPaginationPath(5,pageUrl*5);
    }
    else if (pageUrl){
      path = pageUrl.split('/').pop();

    }
    else{
      path = this.setPaginationPath(5,0);
    }

    return path;
  }

   getFaitss(pageUrl): Observable<FaitPagination> {
    return this.http.get<FaitPagination>(this.sanitizeUrl(this.apiURL + '/faits/'+ this.getPath(pageUrl)), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  /* getFaitss(): Observable<Faits[]> {
    return this.http.get<Faits[]>(this.apiURL + '/faits/'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }  */

  createFait(fait:Faits): Observable<Faits> {
    return this.http.post<Faits>(this.sanitizeUrl(this.apiURL + '/faits/'), JSON.stringify(fait), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  createWarehouse(wh:Warehouse): Observable<Warehouse> {
    return this.http.post<Warehouse>(this.sanitizeUrl(this.apiURL + '/warehouse/'), JSON.stringify(wh), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  deleteFait(fait: Faits){
    return this.http.delete<any>(this.sanitizeUrl(this.apiURL + '/faits/' + fait.id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  deleteWarehouse(wh: Warehouse){
    return this.http.delete<any>(this.sanitizeUrl(this.apiURL + '/warehouse/' + wh.id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  updateFait(fait: Faits):Observable<Faits>{
    return this.http.put<Faits>(this.sanitizeUrl(this.apiURL + '/faits/' + fait.id +'/'), JSON.stringify(fait), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  updateWarehouse(wh: Warehouse):Observable<Warehouse>{
    return this.http.put<Warehouse>(this.sanitizeUrl(this.apiURL + '/warehouse/' + wh.id +'/'), JSON.stringify(wh), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }



  deletePlanificator(planificator:PlanificatorUser): Observable<PlanificatorUser[]> {
    return this.http.delete<PlanificatorUser[]>(this.sanitizeUrl(this.apiURL + '/planificator/' + planificator.pid+'/'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getPlanificateurss(page): Observable<PlanificatorUser[]> {
    return this.http.get<PlanificatorUser[]>(this.sanitizeUrl(this.apiURL + '/planificator/'+this.getPath(page)), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getStockManagerUsers(pageUrl): Observable<FullUserPagination> {
    return this.http.get<FullUserPagination>(this.sanitizeUrl(this.apiURL + '/stockmanageruser/'+ this.getPath(pageUrl)), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  setPlanificator(plId:number, agendaId: number, weekNulmber:number, dateStart:NgbDateStruct): Observable<any> {
    let json_obj = {'plId':plId,"agendaId":agendaId,"weekNumber":weekNulmber,"dateStart":dateStart};

    return this.http.put<any>(this.sanitizeUrl(this.apiURL + '/planificator/0/'), JSON.stringify(json_obj), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  createParcours(parcours:Parcours): Observable<Parcours[]> {
    return this.http.post<Parcours[]>(this.sanitizeUrl(this.apiURL + '/parcours/'), JSON.stringify(parcours), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  updateParcours(parcours:Parcours): Observable<Parcours[]> {
    return this.http.put<Parcours[]>(this.sanitizeUrl(this.apiURL + '/parcours/' + parcours.id+'/'), JSON.stringify(parcours), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  uploadCSV(csv:FormData): Observable<any> {
    const httpOptionsPostImg = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Authorization': 'JWT ' + this._userService.getToken(),
        'Content-Disposition': 'attachment; filename='+csv.get('file')['name']
      })
    }

    return this.http.post<any>(this.sanitizeUrl(this.apiURL + '/csv/'), csv, httpOptionsPostImg)
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }
  updateParcoursImage(id:number, image:FormData): Observable<any> {

    const httpOptionsPostImg = {
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        'Authorization': 'JWT ' + this._userService.getToken(),
        'Content-Disposition': 'attachment; filename='+image.get('file')['name']
      })
    }

    return this.http.patch<any>(this.sanitizeUrl(this.apiURL + '/parcours/' + id+'/'), image, httpOptionsPostImg)
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  deleteParcours(parcours:Parcours): Observable<Parcours[]>{
    return this.http.delete<Parcours[]>(this.sanitizeUrl(this.apiURL + '/parcours/' + parcours.id+'/'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }



  //Parcours
  // HttpClient API get() method => Fetch employees list
  getParcourss(page): Observable<ParcoursPagination> {
    return this.http.get<ParcoursPagination>(this.sanitizeUrl(this.apiURL + '/parcours/' + this.getPath(page)), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getParcoursById(pid): Observable<Parcours> {
    return this.http.get<Parcours>(this.sanitizeUrl(this.apiURL + '/parcours/' + pid), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }
/*
  // HttpClient API get() method => Fetch employee
  getParcours(id): Observable<Parcours> {
    return this.http.get<Parcours>(this.apiURL + '/parcours/' + id)
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  // HttpClient API post() method => Create employee
  createParcours(parcours:Parcours): Observable<Parcours> {
    return this.http.post<Parcours>(this.apiURL + '/parcours'), JSON.stringify(parcours), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  // HttpClient API put() method => Update employee
  updateParcours(id, parcours): Observable<Parcours> {
    return this.http.put<Parcours>(this.apiURL + '/parcours/' + id, JSON.stringify(parcours), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  // HttpClient API delete() method => Delete employee
  deleteParcours(id){
    return this.http.delete<Parcours>(this.apiURL + '/parcours/' + id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

*/

deleteAgenda(agenda): Observable<Agenda[]>{
  return this.http.delete<Agenda[]>(this.sanitizeUrl(this.apiURL + '/agenda/' + agenda.id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

deleteItem(item): Observable<Item[]>{
  return this.http.delete<Item[]>(this.sanitizeUrl(this.apiURL + '/item/' + item.id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

deleteGItem(item:GItem): Observable<GItem[]> {
  return this.http.delete<GItem[]>(this.sanitizeUrl(this.apiURL + '/globalitem/'+ item.id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

deleteLangue(langue): Observable<Langue[]>{
  return this.http.delete<Langue[]>(this.sanitizeUrl(this.apiURL + '/langue/' + langue.id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

deleteIType(itype): Observable<IType[]>{
  return this.http.delete<IType[]>(this.sanitizeUrl(this.apiURL + '/itemtype/' + itype.id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

createAgenda(agenda:Agenda): Observable<Agenda[]> {
  return this.http.post<Agenda[]>(this.sanitizeUrl(this.apiURL + '/agenda/'), JSON.stringify(agenda), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

createItem(item:Item): Observable<Item[] | GItem[]> {
  return this.http.post<Item[] | GItem[]>(this.sanitizeUrl(this.apiURL + '/item/'), JSON.stringify(item), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

createGItem(item:GItem): Observable<GItem[]> {
  return this.http.post<GItem[]>(this.sanitizeUrl(this.apiURL + '/globalitem/'), JSON.stringify(item), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


createLangue(langue:Langue): Observable<Langue[]> {
  return this.http.post<Langue[]>(this.sanitizeUrl(this.apiURL + '/langue/'), JSON.stringify(langue), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

createItype(itype:IType): Observable<IType[]> {
  return this.http.post<IType[]>(this.sanitizeUrl(this.apiURL + '/itemtype/'), JSON.stringify(itype), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getAgendas(page): Observable<AgendaPagination> {
  return this.http.get<AgendaPagination>(this.sanitizeUrl(this.apiURL + '/agenda/'+this.getPath(page)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getAgendaByParcoursId(pid): Observable<Agenda> {

  return this.http.patch<Agenda>(this.sanitizeUrl(this.apiURL + '/agenda/'+pid),null ,this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getGItems(pageUrl): Observable<GItemPagination> {
  return this.http.get<GItemPagination>(this.sanitizeUrl(this.apiURL + '/globalitem/'+this.getPath(pageUrl)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getItems(pageUrl): Observable<ItemPagination> {
  return this.http.get<ItemPagination>(this.sanitizeUrl(this.apiURL + '/item/'+this.getPath(pageUrl)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getLangues(pageUrl): Observable<LanguePagination> {
  return this.http.get<LanguePagination>(this.sanitizeUrl(this.apiURL + '/langue/'+ this.getPath(pageUrl)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getItemTypes(pageUrl): Observable<ItemTypesPagination> {
  return this.http.get<ItemTypesPagination>(this.sanitizeUrl(this.apiURL + '/itemtype/'+ this.getPath(pageUrl)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getWarehouses(pageUrl): Observable<WarehousePagination> {
  return this.http.get<WarehousePagination>(this.sanitizeUrl(this.apiURL + '/warehouse/'+ this.getPath(pageUrl)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getRulesAttributions(pageUrl): Observable<WarehousePagination> {
  return this.http.get<WarehousePagination>(this.sanitizeUrl(this.apiURL + '/rules/'+ this.getPath(pageUrl)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}



editAgenda(agenda): Observable<Agenda[]> {
  return this.http.put<Agenda[]>(this.sanitizeUrl(this.apiURL + '/agenda/'+agenda.id+"/"), JSON.stringify(agenda), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

editItem(item): Observable<Item[]| GItem[]> {
  return this.http.put<Item[] | GItem[]>(this.sanitizeUrl(this.apiURL + '/item/'+item.id+"/"), JSON.stringify(item), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

editGItem(item:GItem): Observable<GItem[]> {
  return this.http.put<GItem[]>(this.sanitizeUrl(this.apiURL + '/globalitem/'), JSON.stringify(item), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

editLangue(langue): Observable<Langue[]> {
  return this.http.put<Langue[]>(this.sanitizeUrl(this.apiURL + '/langue/'+langue.id+"/"), JSON.stringify(langue), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

editIType(itype:IType): Observable<IType[]> {
  return this.http.put<IType[]>(this.sanitizeUrl(this.apiURL + '/itemtype/'+itype.id+"/"), JSON.stringify(itype), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getPlanificatorWeekByUserId(user_id): Observable<PlanWeek[]> {
  return this.http.get<PlanWeek[]>(this.sanitizeUrl(this.apiURL + '/planificator/' + user_id+'/'), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

setCreduUnit(credunit): Observable<credUnitList> {
  return this.http.post<credUnitList>(this.sanitizeUrl(this.apiURL + '/credunit/'), JSON.stringify(credunit), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

createRulesAttributions(rulesAttr): Observable<RulesAttr> {
  return this.http.post<RulesAttr>(this.sanitizeUrl(this.apiURL + '/rules/'), JSON.stringify(rulesAttr), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

updateRulesAttributions(rulesAttr:RulesAttr): Observable<RulesAttr> {
  return this.http.put<RulesAttr>(this.sanitizeUrl(this.apiURL + '/rules/'+rulesAttr.id), JSON.stringify(rulesAttr), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

deleteRulesAttributions(rulesAttr:RulesAttr) {
  return this.http.delete<RulesAttr>(this.sanitizeUrl(this.apiURL + '/rules/'+rulesAttr.id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getPlanificatorAgenda(agendadata): Observable<PlanificatorAgenda> {
  return this.http.post<PlanificatorAgenda>(this.sanitizeUrl(this.apiURL + '/planificator/'), JSON.stringify(agendadata), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getRapports(): Observable<Rapport> {
  return this.http.get<Rapport>(this.sanitizeUrl(this.apiURL + '/rapport'), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


getNotificationByUser(user_id): Observable<MyNotification[]> {
  if(user_id != undefined && user_id > 0){
    return this.http.get<MyNotification[]>(this.sanitizeUrl(this.apiURL + '/notification/' + user_id+'/'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

}

// HttpClient API post() method => Create employee
createNotification(notification:MyNotification): Observable<MyNotification> {
  return this.http.post<MyNotification>(this.sanitizeUrl(this.apiURL + '/notification/'), JSON.stringify(notification), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

createNotificationEmailorSMS(notification:MyNotification): Observable<CallForAction | SmsStatus> {
  return this.http.put<CallForAction | SmsStatus>(this.sanitizeUrl(this.apiURL + '/notification/0/'), JSON.stringify(notification), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


getNotificationMetrixByUser(user_id): Observable<MyNotificationMetrix> {
  return this.http.get<MyNotificationMetrix>(this.sanitizeUrl(this.apiURL + '/notificationMetrix/' + user_id+'/'), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

// HttpClient API post() method => Create employee
createNotificationMetrix(notification:MyNotificationMetrix): Observable<MyNotificationMetrix> {
  return this.http.post<MyNotificationMetrix>(this.sanitizeUrl(this.apiURL + '/notificationMetrix/'), JSON.stringify(notification), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getUserMetrix(id): Observable<userMetrix> {
  return this.http.get<userMetrix>(this.sanitizeUrl(this.apiURL + '/usermetrix/' + id+'/'), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

// HttpClient API get() method => Fetch employee
getRapport(id): Observable<Rapport> {
  return this.http.get<Rapport>(this.sanitizeUrl(this.apiURL + '/rapport/' + id+'/'), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

// HttpClient API post() method => Create employee
createRapport(rapport:Rapport): Observable<Rapport> {
  return this.http.post<Rapport>(this.sanitizeUrl(this.apiURL + '/rapport/'), JSON.stringify(rapport), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

// HttpClient API put() method => Update employee
updateRapport(rapport: Rapport): Observable<Rapport> {
  return this.http.put<Rapport>(this.sanitizeUrl(this.apiURL + '/rapport/' + rapport.pk+'/'), JSON.stringify(rapport), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

// HttpClient API delete() method => Delete employee
deleteRapport(id){
  return this.http.delete<Rapport>(this.sanitizeUrl(this.apiURL + '/rapport/' + id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


updateUserPassword(user_id,old_password,new_password,new_password2): Observable<Updatepwd> {

  let output: JSON;
  let obj: any =  {
    "user_id": user_id,
    "old_password": old_password,
    "new_password": new_password,
    "new_password2": new_password2
  }
  output = <JSON>obj;

  return this.http.put<Updatepwd>(this.sanitizeUrl(this.apiURL + '/changePasswd/1/'), JSON.stringify(output), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

  // HttpClient API get() method => Fetch employees list
  getAssemblees(): Observable<Assemblee> {
    return this.http.get<Assemblee>(this.sanitizeUrl(this.apiURL + '/assemblee'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  // HttpClient API get() method => Fetch employee
  getAssemblee(id): Observable<Assemblee> {
    return this.http.get<Assemblee>(this.sanitizeUrl(this.apiURL + '/assemblee/' + id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  // HttpClient API post() method => Create employee
  createAssemblee(assemblee:Assemblee): Observable<Assemblee> {
    return this.http.post<Assemblee>(this.sanitizeUrl(this.apiURL + '/assemblee'), JSON.stringify(assemblee), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  // HttpClient API put() method => Update employee
  updateAssemblee(id, assemblee): Observable<Assemblee> {
    return this.http.put<Assemblee>(this.sanitizeUrl(this.apiURL + '/assemblee/' + id), JSON.stringify(assemblee), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  // HttpClient API delete() method => Delete employee
  deleteAssemblee(id){
    return this.http.delete<Assemblee>(this.sanitizeUrl(this.apiURL + '/assemblee/' + id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }





private getTimeFormat(time:Time){

  let min = "";
  let hour = "";
  if(time.hour < 10){
    hour = "0"+time.hour;
  }else{
    hour = ""+time.hour;
  }

  if(time.minute < 10){
    min = "0"+time.minute;
  }else{
    min = ""+time.minute;
  }

  return hour+":"+min+":00";

}



createDispo(dispo:Dispo): Observable<Dispo> {
  let output: JSON;
  let obj: any =  {
    "dispos_per_week":dispo.dispos_per_week,
    "heure_debut" :this.getTimeFormat(dispo.heure_debut),
    "heure_fin" : this.getTimeFormat(dispo.heure_fin),
    "lundi": dispo.lundi,
    "mardi" : dispo.mardi,
    "mercredi" : dispo.mercredi,
    "jeudi" : dispo.jeudi,
    "vendredi" : dispo.vendredi,
    "samedi" : dispo.samedi,
    "dimanche" : dispo.dimanche
    }

  output = <JSON>obj;


  return this.http.post<Dispo>(this.sanitizeUrl(this.apiURL + '/dispo/'), JSON.stringify(output),  this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


updateDispo( dispo:Dispo): Observable<Dispo> {
  let output: JSON;
  let obj: any =  {
    "dispos_per_week":dispo.dispos_per_week,
    "heure_debut" : this.getTimeFormat(dispo.heure_debut),
    "heure_fin" : this.getTimeFormat(dispo.heure_fin),
    "lundi": dispo.lundi,
    "mardi" : dispo.mardi,
    "mercredi" : dispo.mercredi,
    "jeudi" : dispo.jeudi,
    "vendredi" : dispo.vendredi,
    "samedi" : dispo.samedi,
    "dimanche" : dispo.dimanche,
    "id":dispo.id
    }

  output = <JSON>obj;
  return this.http.put<Dispo>(this.sanitizeUrl(this.apiURL + '/dispo/' + dispo.id + '/'), JSON.stringify(output), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

deleteDispo(id){
  return this.http.delete<Dispo>(this.sanitizeUrl(this.apiURL + '/dispo/' + id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getDispoPerWeek(id): Observable<DispoPerWeek> {
  return this.http.get<DispoPerWeek>(this.sanitizeUrl(this.apiURL + '/disposperweek/'+id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


getDispo(id): Observable<Dispo> {
  return this.http.get<Dispo>(this.sanitizeUrl(this.apiURL + '/dispo/'+id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getUser(user_id): Observable<FullUser> {
  return this.http.get<FullUser>(this.sanitizeUrl(this.apiURL + '/users/'+user_id+'/'), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )

}

getPUsers(page): Observable<FullUserPagination> {
  return this.http.get<FullUserPagination>(this.sanitizeUrl(this.apiURL + '/pusers/'+this.getPath(page)), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


 // HttpClient API put() method => Update employee
 updateUser(user:FullUser): Observable<FullUser> {
  return this.http.put<FullUser>(this.sanitizeUrl(this.apiURL + '/users/' + user.id+"/"), JSON.stringify(user), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


getCreneauWithCred(agendadata): Observable<AgendCredInstanceListbyWeek> {
  return this.http.post<AgendCredInstanceListbyWeek>(this.sanitizeUrl(this.apiURL + '/creneauwithcred/'), JSON.stringify(agendadata), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getCreneauWithCredbyDay(agendadata): Observable<AgendCredInstanceListbyDay> {
  return this.http.post<AgendCredInstanceListbyDay>(this.sanitizeUrl(this.apiURL + '/creneauwithcred/'), JSON.stringify(agendadata), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getCreneaux(page): Observable<CreneauPagination> {
  return this.http.get<CreneauPagination>(this.sanitizeUrl(this.apiURL + '/creneau' + this.getPath(page)),this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error)),
  )
}

getCreneau(id): Observable<Creneau> {
  return this.http.get<Creneau>(this.sanitizeUrl(this.apiURL + '/creneau/' + id),this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error)),
  )
}

updateCreneau(creneau): Observable<Creneau[]> {
  return this.http.put<Creneau[]>(this.sanitizeUrl(this.apiURL + '/creneau/' + creneau.id+"/"), JSON.stringify(creneau), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

updateCreInstance(creinstance): Observable<UserCredInstance> {
  return this.http.put<UserCredInstance>(this.sanitizeUrl(this.apiURL + '/creinstance/' + creinstance.cre_id+"/"), JSON.stringify(creinstance), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

updateCreInstanceBulk(data): Observable<any> {
  return this.http.patch<any>(this.sanitizeUrl(this.apiURL + '/creinstance/'), JSON.stringify(data), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

getCredUnit(date, day,cred_id): Observable<credUnitList> {
  let data = {
    date:date,
    jour:day,
    cred_id:cred_id
  }
  return this.http.post<credUnitList>(this.sanitizeUrl(this.apiURL + '/creinstance/'), JSON.stringify(data), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

deleteCreneaun(id){
  return this.http.delete<any>(this.sanitizeUrl(this.apiURL + '/creneau/' + id), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

createCreneau(attribution:Creneau): Observable<Creneau[]> {

  return this.http.post<Creneau[]>(this.sanitizeUrl(this.apiURL + '/creneau/'), JSON.stringify(attribution), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


deleteAttribution(id,cancel:boolean=false){
  return this.http.delete<Attribution>(this.sanitizeUrl(this.apiURL + '/attribution/' + id+"_"+cancel), this.httpOptions())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}


createAttributions(attribution:Attribution): Observable<AttributionCreation> {

  return this.http.post<AttributionCreation>(this.sanitizeUrl(this.apiURL + '/attribution/'), JSON.stringify(attribution), this.httpOptionsPost())
  .pipe(
    retry(this.retry),
    catchError(error => this.handleError(error))
  )
}

 //UserList
 getTPSUserbyId(user_id): Observable<FullUser> {
  return this.getUser(user_id);
}
  // HttpClient API get() method => Fetch employees list
  getTPSUsersAuto(pattern): Observable<FullUser> {
    return this.http.get<FullUser>(this.sanitizeUrl(this.apiURL + '/autouser/' + pattern), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

//CreInstance
  // HttpClient API get() method => Fetch employee
  getCredinstancesByUserId(id): Observable<UserCredInstanceList> {

    return this.http.get<UserCredInstanceList>(this.sanitizeUrl(this.apiURL + '/usercreinst/' + id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  getCredinstancesByUserDispo(id): Observable<UserCredInstanceList> {

    return this.http.get<UserCredInstanceList>(this.sanitizeUrl(this.apiURL + '/crenbydispo/' + id+"/"), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getStats():Observable<statOfMonth> {
    return this.http.get<statOfMonth>(this.sanitizeUrl(this.apiURL + '/statsmonth/'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getStatsByMonth(month,year,csv):Observable<StatForMonth|CSVStats> {

    let data = {}
    if (month != null && year != null){
      data = {
        "month":month,
        "year":year
      }
    }

    if(csv){
      data["csv"] = true
    }



    return this.http.post<StatForMonth|CSVStats>(this.sanitizeUrl(this.apiURL + '/statspermonth/'), JSON.stringify(data), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getCandidateList(cre_id): Observable<CandidateDispo[]> {
    return this.http.get<CandidateDispo[]>(this.sanitizeUrl(this.apiURL + '/alldispo/'+cre_id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }



  getCredinstancesByUserIdandPagination(pageUrl,isFutur): Observable<UserCredInstancePagination> {
    if (pageUrl == null){
      pageUrl = this.getPath(pageUrl);
    }

    return this.http.get<UserCredInstancePagination>(this.sanitizeUrl(this.apiURL + '/usercreinst/'+ pageUrl+'&isfutur='+isFutur), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getAdminSettings(pageUrl): Observable<AdminSettingsPagination>{
    return this.http.get<AdminSettingsPagination>(this.sanitizeUrl(this.apiURL + '/adminsettings/'+ this.getPath(pageUrl)), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getMessageAlert(pageUrl): Observable<MessageAlertPagination>{
    return this.http.get<MessageAlertPagination>(this.sanitizeUrl(this.apiURL + '/messagealerte/'+ this.getPath(pageUrl)), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  getSuggestions(pageUrl): Observable<SuggestionsPagination>{
    return this.http.get<SuggestionsPagination>(this.sanitizeUrl(this.apiURL + '/suggestions/'+ this.getPath(pageUrl)), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  updateAdminSettings(st: AdminSettings):Observable<AdminSettings>{
    return this.http.put<AdminSettings>(this.sanitizeUrl(this.apiURL + '/adminsettings/' + st.id +'/'), JSON.stringify(st), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  updateMessageAlert(message: MessageAlert):Observable<MessageAlert>{
    return this.http.put<MessageAlert>(this.sanitizeUrl(this.apiURL + '/messagealerte/' + message.id +'/'), JSON.stringify(message), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  createAdminSettings(st:AdminSettings): Observable<AdminSettings> {
    return this.http.post<AdminSettings>(this.sanitizeUrl(this.apiURL + '/adminsettings/'), JSON.stringify(st), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  createMessageAlert(mess:MessageAlert): Observable<MessageAlert> {
    return this.http.post<MessageAlert>(this.sanitizeUrl(this.apiURL + '/messagealerte/'), JSON.stringify(mess), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getConnectedPeople():Observable<ConnectedPeople[] | ConnectedPeopleLimited[]> {
    return this.http.get<ConnectedPeople[] | ConnectedPeopleLimited[]>(this.sanitizeUrl(this.apiURL + '/connected'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getpwdChangeStatus(data: pwdChangeRequestObj): Observable<PwdChangeObj> {
    return this.http.post<PwdChangeObj>(this.sanitizeUrl(this.apiURL + '/pwdchange'), JSON.stringify(data), this.httpOptionsPostAnonymous())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getRulesTemplate():Observable<any> {
    return this.http.get<any>(this.sanitizeUrl(this.apiURL + '/rules/base'), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }

  getRuleByCreinstance(creinstanceId:number):Observable<RuleDescription[]> {
    return this.http.get<any>(this.sanitizeUrl(this.apiURL + '/ruledes/'+creinstanceId), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  listRuleByCreneau(id): Observable<RuleCreneauPagination> {
    return this.http.get<RuleCreneauPagination>(this.sanitizeUrl(this.apiURL + '/rulescreneau/' + id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  createRuleCreneau(rc:RuleCreneau): Observable<RuleCreneau> {
    return this.http.post<RuleCreneau>(this.sanitizeUrl(this.apiURL + '/rulescreneau'), JSON.stringify(rc), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  updateRuleCreneau(id, rc:RuleCreneau): Observable<RuleCreneau> {
    return this.http.put<RuleCreneau>(this.sanitizeUrl(this.apiURL + '/rulescreneau/' + id), JSON.stringify(rc), this.httpOptionsPost())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }


  deleteRuleCreneau(id){
    return this.http.delete<any>(this.sanitizeUrl(this.apiURL + '/rulescreneau/' + id), this.httpOptions())
    .pipe(
      retry(this.retry),
      catchError(error => this.handleError(error))
    )
  }
  consolelog(title, log) {
    if (!environment.production) {
      console.log("----------" + title + "------------------");
      console.log(log);
      console.log();
      }
    }
  // Error handling
  handleError(error) {

    let toBeTosated = true;


    this.consolelog("handleError",error)
    this.consolelog("handleErrorStatus",error.status)
    this.consolelog("handleErrorMessage",error.error)

    this.toastr.error( error.statusText );

    if (error.status == 403 || error.status == 401){
      if (this._userService.isLoggedIn()){
        this._userService.logout();
      }
    }



     let errorMessage = '';
     if (error.status == 404){
      errorMessage="Ressource inconnue"
     }else{
      if(error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
       // Get server-side error
       errorMessage = JSON.stringify(error.error);

       /*errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
       if (error.error.length > 0){
         errorMessage += `\nError Message: ${error.error[0]}`;
       }
       if (error.error){
         errorMessage = JSON.stringify(error.error);

       }*/

      }
     }

     if( this._userService.isLoggedIn()){

      this.toastr.error(errorMessage);
     }


     return throwError(errorMessage);
  }


  getPolling(user_id): Observable<Polling> {
    if(user_id != undefined && user_id > 0){
      return this.http.get<Polling>(this.sanitizeUrl(this.apiURL + '/polling/' + user_id+'/'), this.httpOptions())
      .pipe(
        retry(this.retry),
        catchError(error => this.handleError(error))
      )
    }

  }

}
