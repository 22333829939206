import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
export class CalledUsers{
  ref_id: number;
  nom: string;
  prenom: string;
  email: string;
  telephone: string;
}
export class CallForAction{
  is_mail_sent: boolean;
  created:  string;
  calledusers: CalledUsers[];
}

export class Pagniation{
    count: number;
    next: string;
    previous: string;
    current: number;
    results: any[];

    constructor(data: any) {
        this.count = data.count;
        this.next = data.next;
        this.previous = data.previous;
        if (this.previous == undefined){
            this.current = 1;
        }else if (this.next == undefined){
            this.current = Math.ceil(this.count/5)
        }else{
            this.current  = Math.ceil((parseInt(this.next.match(/.*offset=([0-9]+).*/).pop())-5)/5)+1;
        }
    }

    getCurrentPage(del=false,add=false){


      if (this.current == undefined){
        this.current = 1;
      }

      let change = del || add;

      if (del){
        if (this.count != undefined ){
          if (this.count -1 >=0){
            this.count -=1;
          }
        }else{
          this.count = 0;
        }
      }
      if (add){
        if (this.count != undefined ){
          this.count += 1;
        }else{
          this.count = 1;
        }
      }




      if (change){
        let max_current_index = this.current*5;
        let min_current_index = max_current_index-5;

        if (this.count> max_current_index ){//need to go to last page - added element
          let last_index =  Math.floor(this.count/5);
          this.current = last_index+1;
        }else if(this.count <= min_current_index){

          this.current -= 1;
        }


      }


      let currentPage = "?limit=5&offset="+(this.current-1)*5;

      return currentPage;
    }
}

export class FaitPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Faits[];
    }
}

export class GItemPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as GItem[];
    }
}
export class ItemPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Array<Item | GItem>;
    }
}

export class LanguePagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Langue[];
    }
}

export class ItemTypesPagination extends Pagniation{

  constructor(data: any) {
      super(data);
      this.results = data.results as IType[];
  }
}


export class WarehousePagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Warehouse[];
    }
}


export class FullUserPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as FullUser[];
    }
}


export class AgendaPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Agenda[];
    }
}

export class CreneauPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Creneau[];
    }
}

export class ParcoursPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Parcours[];
    }
}



export class PlanificatorAllPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as PlanificatorUser[];
    }
}



export class UserCredInstanceList {
    clist: Array<string>;
}

export class AgendCredInstanceList {
    clist: Array<any>;
    agenda: Agenda;
    planificator: FullUser;
    plan_month: Array<PlanificatorsUser>
    start_at:ngDate
    amount_of_weeks: number;
}



export class AgendCredInstanceListbyWeek extends AgendCredInstanceList{
    lundi: string;
    mardi: string;
    mercredi: string;
    jeudi: string;
    vendredi: string;
    samedi: string;
    dimanche: string;
}

export class AgendCredInstanceListbyDay extends AgendCredInstanceList{
    today: string;
}

export class ReportData{
  date:string;
  agenda:string;
  parcours:string
  creneau:string;
  attributions:UserAttribution[]
}
export class Faits{
    created: string;
    fait: string;
    parcours: Parcours;
    id: number;
    reportData:ReportData;
    moderation: boolean;

    constructor(){
        this.parcours = new Parcours();
    }


}
export class Creneau{
    id: number;
    heure_debut: Time;
    heure_fin: Time;
    agendas: number[];
    lundi: boolean;
    mardi: boolean;
    mercredi: boolean;
    jeudi: boolean;
    vendredi: boolean;
    samedi: boolean;
    dimanche: boolean;
    need_brother: boolean;
    rules: RuleCreneau[];
}

export class userMetrix{
    coming: number;
    reportWaiting: number;
    passed: number;
}

export class Parcours{
    id: number;
    name : string;
    description: string;
    created: string;
    agendas: number[];
    image:any;
    warehouse: number;
}



export class RapportL{
    nb_item:number;
    item: GItem;
    pk:number;

    constructor(gi: GItem){
        this.item = gi;
    }
}

export class Rapport{
    faits: Faits;
    suggestions: string;
    creInstance:number;
    pk:number;
    rapports: RapportL[];

    constructor(){

        this.faits = new Faits();
        this.rapports = new Array<RapportL>();

    }
    public init_items(gitems:GItem[]){
        for (let gi of gitems){
            this.rapports.push(new RapportL(gi));
        }
    }
    public load(data){
        /*
        this.nb_livre = data.nb_livre;
        this.nb_revues= data.nb_revues;
        this.nb_tracts_et_invits= data.nb_tracts_et_invits;
        this.nb_brochures= data.nb_brochures;
        this.nb_cartes_visites= data.nb_cartes_visites;
        this.nb_site_et_videos= data.nb_site_et_videos;
        this.nb_etudes= data.nb_etudes;
        this.nb_visites= data.nb_visites;
        */
        this.rapports = data.rapports;
        this.suggestions= data.suggestions;
        this.creInstance= data.creInstance;
        this.pk= data.pk;

        if (data.faits){
            this.faits = data.faits;
        }
    }

}

export class ngDate{
    year: number;
    month: number;
    day: number;
}
export class Agenda{
    id: number;
    name : string;
    description: string;
    created : string;
    disabled_at: ngDate;
    enabled_at: ngDate;
    parcours:number;
    parcours_name:string;
    color: string;
}

export class Agendadata{
    agenda_id: number;
    position:number;
    tdate: ngDate;
    user_id:number;
    viewType: string;

    constructor(agenda_id: number,user_id:number) {
        this.agenda_id = agenda_id;
        this.user_id = user_id;
        this.viewType = "week";
    }


}

export class CreneauWithCred{
    date: string;
    cren_id: number;
    heure_debut: string;
    heure_fin: string;
}
export class CreneauWithCredbyWeek extends CreneauWithCred {
    lundi: UserCredInstance;
    mardi: UserCredInstance;
    mercredi: UserCredInstance;
    jeudi: UserCredInstance;
    vendredi: UserCredInstance;
    samedi: UserCredInstance;
    dimanche: UserCredInstance;
}

export class CreneauWithCredbyday extends CreneauWithCred{
    today: UserCredInstance;

}
export class UserCredInstance{
    cre_id:number;
    date: string;
    creneau: string;
    cre_instance_id: number;
    attributions: Array<UserAttribution>;
    report: number;
    parcours_name:string;
    agenda_name:string;
    pres_take_in : boolean;
    pres_take_out : boolean;
    remaining_proc: number;
    is_urgent: boolean;
    jour: string;
    is_brother_needed: boolean;
    is_off: boolean;
    off_reason:string;
    creneau_id:number;
}

export class UserAttribution{
    email: string;
    nom: string;
    prenom: string;
    telephone: string;
    user_id: number;
    attr_id: number;
    gender : Gender;
    under_review: boolean;
}

enum Gender {
    Frère = 0,
    Soeur = 1,
}

export class RegisterUser {
    email: string;
    nom: string;
    prenom: string;
    token: string;
    passwd: string;
}

export class TPSUser {

    id: number;
    gender: Gender;
    email: string;
    nom: string;
    prenom: string;
    telephone: string;
    dipos_per_week: number[];
    assemblee: number;
}

export class SpinnerCredAction{
  cred_id: number;
  loading: boolean;
}

export class SpinnerAgenda{
  agenda_id: number;
  loading: boolean;
}
export class FullUser extends TPSUser{
    is_admin : boolean;
    is_planificator : boolean;
    is_stock_manager : boolean;
    is_global_manager : boolean;
    /* is_elder : boolean;
    is_assistant: boolean;
    is_pp : boolean;
    is_pa : boolean;
    is_ps: boolean; */
    rgpd_timestamp: string;
    noDispo:boolean;
    unknownDispo:boolean;
}


export class Attribution {
    creinstance : number;
    user: number;
    under_review: boolean;
}

export class Time{
    hour: number;
    minute: number;
    second: number;
    constructor() {
        this.hour = 0;
        this.minute = 0;
        this.second = 0;
    }
}

export class DispoPerWeek{
    id: number;
    name: string;
    comment: string;
    created: string;
    dispo: number[];
}


export class AttributionCreation{
  id: number;
  creinstance: number;
  created: string;
  under_review:boolean;
}

export class Assemblee{
    name:string;
    procs: number[];
}
export class PlanWeek{
    week:Date;
    weeknumber: number;
    agendaList: number[];
}
export enum PriorityEnum {
    INFO="INFO",
    IMPORTANT="IMPORTANT",
    PERSO="PERSO",
}

export class MyNotification{
    id: number;
    created: string;
    ffrom: string;
    notification: string;
    users: number[];
    priority: PriorityEnum;
    cred: number;
    email:boolean;
    sms:boolean;
}

export class MyNotificationMetrix{
    id: number;
    is_red: boolean;
    user:  number;
    notification:  number;
    red_time: string;
}

export class Dispo{
    id: number;
    heure_debut: Time;
    heure_fin : Time;
    dispos_per_week: number;
    lundi : boolean;
    mardi : boolean;
    mercredi : boolean;
    jeudi : boolean;
    vendredi : boolean;
    samedi : boolean;
    dimanche : boolean;

    constructor(dispos_per_week: number){
        this.dispos_per_week = dispos_per_week;
        this.id=0;
        this.lundi = false;
        this.mardi = false;
        this.mercredi = false;
        this.jeudi = false;
        this.vendredi = false;
        this.samedi = false;
        this.dimanche = false;
    }

    public set(
        id: number,
        heure_debut: string,
        heure_fin: string,
        lundi : boolean,
        mardi : boolean,
        mercredi : boolean,
        jeudi : boolean,
        vendredi : boolean,
        samedi : boolean,
        dimanche : boolean
        ) {

        this.id = id;
        this.heure_debut = this.getDateFormat(heure_debut);
        this.heure_fin = this.getDateFormat(heure_fin);
        this.lundi = lundi;
        this.mardi = mardi;
        this.mercredi = mercredi;
        this.jeudi = jeudi;
        this.vendredi = vendredi;
        this.samedi = samedi;
        this.dimanche = dimanche;
    }

    private getDateFormat(date_string){
        let t = new Time()
        t.hour = Number(date_string.split(":")[0])
        t.minute = Number(date_string.split(":")[1])
        return t;
    }


}
export class credUnitList{
    jour: string;
    date: ngDate;
    heure_debut: string;
    heure_fin: string;
    attributions: Array<UserAttribution>;
    is_urgent: boolean;
    notification_status: number;
    is_brother_needed: boolean;
    max_attributions: number;
    cren_id: number;
    cred_id: number;
    report: number;
    agenda: number;
    is_off:boolean;
    off_reason:string;
}
export class PlanificatorAgenda{
    agenda: Agenda;
    responsable: FullUser;
    date: ngDate;
    credUnitList: Array<credUnitList>;
}

export class AgendadataPlanificator{
    position:number;
    tdate: ngDate;
    agenda_id:number;
    user_id:number;

    constructor(user_id: number, agenda_id: number = 0 ){
        this.user_id = user_id;
        this.agenda_id=agenda_id;
        this.position = 0;
        this.tdate=null;
    }

}

export class PlanificatorUser{
    pid:number;
    responsable:FullUser;
    week:ngDate;
    weeknumber:number;
    agenda:number;
}

export class UserPlan{
  email: string;
  nom: string;
  prenom: string;
  telephone: string;
  id: number;
}
export class Planificator{
  user:number;
  week:ngDate;
  weeknumber:number;
  agenda:number;
}
export class PlanificatorsUser{
  user:UserPlan;
  week:ngDate;
  weeknumber:number;
  agenda:number;
}




export class PlanificatorAll{
    responsables:FullUser[];
    planificator_users:PlanificatorUser[];
}

export class GItem{
    id: number;
    name: string;
    langue: Langue;
    itype: IType
}

export class IType{
  id: number;
  name: string;
  is_material: boolean;
}

export class Item{
    id: number;
    name: string;
    stock_value: number;
    threshold: number;
    langue: Langue;
    itype: IType;
    warehouse: Warehouse;
    responsable: FullUser;
    notif: boolean;

    constructor(){
       this.langue = new Langue();
       this.itype = new IType();
       this.warehouse = new Warehouse();
       this.responsable = new FullUser();
       this.notif = false;
    }
}

export class Langue{
    id: number;
    name: string;
    reference: string;
}

export class Warehouse{
    id: number;
    name: string;
    point: number;
    address: string;
}

export class Updatepwd{
  status:string;
  code:number;
  message: string;
  data: string[];
}

export class SmsStatus{
  sdate:ngDate;
  status: string;
}


export class statCompareTo{
  isBetter:boolean;
  delta: string;

}

export class StatOfMonthItem{
  info:string;
  count:Number;
}
export class statOfMonth{
  month:string;
  date:string;
  contacts:StatOfMonthItem;
  versets:StatOfMonthItem;
  visites:StatOfMonthItem;
  cours:StatOfMonthItem;
}

export class statIType{
  name:string;
  count:number;
  id: number;
}
export class statMonth{
  parcours:string;
  data:statIType[];
}
export class StatForMonth{
  month:string;
  year:string;
  items:IType[];
  data:statMonth[];
}

export class CandidateDispo{
  prenom:string;
  telephone:string;
  gender : Gender;

}

export class UserCredInstancePagination extends Pagniation{

  constructor(data: any) {
      super(data);
      this.results = data.results as UserCredInstance[];
  }


}

export class AdminSettings{
    id:number;
    faq_version:string;
    faq_url:string;
}
export class AdminSettingsPagination extends Pagniation{

    constructor(data:any) {
        super(data);
        this.results = data.results as AdminSettings[];
    }
}

export class CSVStats {
  date: string;
  data:string;
}


export class SuggestionsPagination extends Pagniation{

    constructor(data: any) {
        super(data);
        this.results = data.results as Rapport[];
    }
}

export class MessageAlert {
    id:number;
    message: string;
    diffusion:boolean;
}

export class MessageAlertPagination extends Pagniation{

    constructor(data:any) {
        super(data);
        this.results = data.results as MessageAlert[];
    }


}

export class Polling {
    id:number;
    my_notification: MyNotification[];
    message_alert:MessageAlert[];
}


export class ConnectedPeople{
  nom: string;
  prenom: string;
}

export class ConnectedPeopleLimited{
  is_connected: boolean;

}

export class pwdChangeRequestObj{
  email?:string;
  token?:string;
  otp?:string;
  pwd1?:string;
  pwd2?:string;
}

export class PwdChangeObj{
  token: string;
  error: string;
  changeallowed: boolean;
  secondFA: boolean;
  firstFA: boolean;
  status: string;
  changedone: number;
}

export class RulesAttr{
  id: number;
  rule: string;
  is_active: boolean;
  removable: boolean;
  description: string;

}
export class RulesAttrPagination extends Pagniation{

  constructor(data: any) {
      super(data);
      this.results = data.results as RulesAttr[];
  }
}

export class RuleMethod{
  name:string;
  p1:string;
  p2:number;

  constructor() {
    this.name = "len";
    this.p1 ="INF";
    this.p2 =1;
}
}
export class RuleClass{
  class:string;
  propertie:string;
  val:any;
  op:string;

  constructor() {
    this.class = "User";
    this.propertie ="gender";
    this.val ="0";
}
}


export class RuleElLeaf{
  id:string;
  type:string;
  desc:string;

  constructor() {
    this.id = Date.now().toString();
    this.type ="leaf";
}

}

export class RuleElTree{
  parentId:number;
  id:string;
  type:string;
  conditionList:any[];
  op:string;
  constructor() {
    this.id = Date.now().toString();
    this.type ="tree";
    this.conditionList = [];
    this.op = "AND";
}
}

export class DeleteRuleTree{
  id:string;
  delete:boolean;
  constructor(id) {
    this.id = id;
    this.delete =true;

}

}

export class RuleDescription{
  op:string;
  status:boolean;
  is_root:boolean;
  rules:(string|RuleDescription)[];
}

export class RuleCreneau{
  id:number;
  title:string;
  message:string;
  icon:string;
  creneau:number;
}

export class RuleCreneauPagination extends Pagniation{

  constructor(data: any) {
      super(data);
      this.results = data.results as RuleCreneau[];
  }
}
