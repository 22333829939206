import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { RestApiService } from '../rest-api.service';
import { FullUser } from '../model';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  public user: FullUser;

  public me = new BehaviorSubject<FullUser>(undefined);
  constructor( private _restAPI: RestApiService, private _authService: AuthenticationService) {
    this._authService.authenticationKo.subscribe(
      ok=>{
        if (!ok){
          this.syncMe();
        }
      }
    );
  }



  private syncMe(){
    if (this._authService.getid() == undefined){
      this._authService.getUserInfoFromToken();
    }
      return this._restAPI.getUser(this._authService.getid()).subscribe((data: FullUser) => {
        this.me.next(data);
        this.user = data;
      })
  }
}
