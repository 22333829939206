import { CommonService } from 'src/app/shared/common.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpSentEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY  } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderService implements HttpInterceptor{
  private isAuthenticationKo: Boolean = true;
  constructor(private commonService: CommonService,
    private _userService: AuthenticationService) {
    this._userService.authenticationKo.subscribe({
      next: (v) => {
        this.isAuthenticationKo = v;

      }
    });

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      if (this.isAuthenticationKo &&  req.url != "/api-token-auth/" &&  req.url != "/api-register/" &&  req.url != "/api/pwdchange/") {
          return EMPTY;
        }

        return next.handle(req);

  };
}
