import { Component } from '@angular/core';
import { CommonService } from './shared/common.service';
import {SafeResourceUrl, Title} from "@angular/platform-browser";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  title = this.commonService.getTPSType();

  appletouchicon: HTMLLinkElement = document.querySelector('#apple-touch-icon');
  favicon32x32: HTMLLinkElement = document.querySelector('#favicon-32x32');
  favicon194x194: HTMLLinkElement = document.querySelector('#favicon-194x194');
  androidchrome192x192: HTMLLinkElement = document.querySelector('#android-chrome-192x192');
  favicon16x16: HTMLLinkElement = document.querySelector('#favicon-16x16');
  webmanifest: HTMLLinkElement = document.querySelector('#webmanifest');
  safaripinnedtab: HTMLLinkElement = document.querySelector('#safari-pinned-tab');

    constructor(
     private commonService: CommonService, private sanitizer: DomSanitizer,private titleService:Title) {
      this.appletouchicon.href = this.favicon()+"apple-touch-icon.png";
      this.favicon32x32.href = this.favicon()+"favicon-32x32.png";
      this.favicon194x194.href = this.favicon()+"favicon-194x194.png";
      this.androidchrome192x192.href = this.favicon()+"android-chrome-192x192.png";
      this.favicon16x16.href = this.favicon()+"favicon-16x16.png";
      this.webmanifest.href = this.favicon()+"site.webmanifest";
      this.safaripinnedtab.href = this.favicon()+"safari-pinned-tab.svg";
      this.titleService.setTitle(this.title);
  }

  transform(url: any):SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  favicon(){
    let fbase = "../assets/img/brand/favicon-normal/";

    if (this.commonService.isSpecialActivityFunc()){
      fbase = "../assets/img/brand/favicon-special/";
    }
    return fbase;
  }

  public showIt(){
    // console.log("req",this.commonService.req);
    return this.commonService.req > 0;
  }

}
