// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  tpsstyle: window["env"]["TPS_STYLE"] || "",
  tpslogo: window["env"]["TPS_LOGO"] || "normal",
  tpslogintxt: window["env"]["TPS_LOGIN_TXT"] || "Bienvenue sur le site du TPS Caen",
  modoplink: window["env"]["TPS_MODOP_LINK"] || "https://docs.google.com/document/d/1ftY071UzqSNa4sqOeztCgijwksXCcPZxIIdajP7dqZk/export?format=pdf",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
